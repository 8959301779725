import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const sectionAnimation = async () => {
  gsap.registerPlugin(ScrollTrigger)
  const sections = [
    ...document.querySelectorAll(["main>section:not(:nth-of-type(1)) *"]),
  ]
  sections.forEach(section => {
    const sectionsTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: "top bottom",
        ease: "sine",
      },
    })
    sectionsTimeline.fromTo(
      section,
      {
        scale: 0.9,
        opacity: 0,
        stagger: 0.7,
        duration: 0.5,
      },
      {
        scale: 1,
        opacity: 1,
      }
    )
  })
}
