import gsap from "gsap"
import { desktopMenuAnimation } from "../menuAnimation/desktopMenuAnimation"
import { fixedElementAnimation } from "../fixedElementAnimation/fixedElementAnimation"
import { preloaderAnimation } from "../preloader/preloader"
import { theme } from "../../utils/theme"

export const headerAnimation = async () => {
  const header = document.querySelector(".helloHeader")
  const subHeader = document.querySelector(".helloSubheader")
  const image = document.querySelector(".helloImage")
  const accentColor = document.querySelector(".accent-color")
  const accentColorButton = document.querySelector(
    ".button-hover__accent-color"
  )

  gsap.set([header, subHeader, image], { autoAlpha: 0 })
  gsap.set(accentColor, { color: `${theme.colors.black}` })
  gsap.set(document.querySelector(".menu"), { autoAlpha: 0 })
  gsap.set(
    [
      ...document.querySelectorAll(
        ".navigation-aside-submenu__image-container"
      ),
    ],
    { autoAlpha: 0 }
  )
  gsap.set([...document.querySelectorAll(".contact-floater")], { autoAlpha: 0 })

  const headerTimeLine = gsap.timeline({
    defaults: {
      ease: "expo",
    },
  })

  await preloaderAnimation()

  await headerTimeLine
    .fromTo(
      header,
      { y: "-=300", autoAlpha: 0 },
      { y: "+=300", autoAlpha: 1, duration: 0.7 }
    )
    .fromTo(
      image,
      { x: "+=150", autoAlpha: 0 },
      { x: "-=150", autoAlpha: 1, duration: 1 }
    )
    .fromTo(
      subHeader,
      { y: "-=300", autoAlpha: 0 },
      { y: "+=300", autoAlpha: 1, duration: 1 },
      "-=1"
    )
  headerTimeLine
    .to(accentColor, {
      color: `${theme.colors.accent}`,
    })
    .to(accentColorButton, {
      backgroundColor: `${theme.colors.accent}`,
    })
  desktopMenuAnimation()
  if (window.innerWidth > 1024) {
    fixedElementAnimation()
  }
}
