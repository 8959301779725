import gsap from "gsap"

export const fixedElementAnimation = () => {
  const contactFloaters = [...document.querySelectorAll(".contact-floater")]

  const contactFloatersTimeLine = gsap.timeline({
    defaults: {
      ease: "expo",
    },
  })

  contactFloaters.forEach((el, index) => {
    gsap.set(el, { y: 400 })
    const timeAnimation = 1

    contactFloatersTimeLine.to(
      el,
      {
        y: 0,
        autoAlpha: 1,
        duration: timeAnimation,
      },
      `-=${index * timeAnimation}`
    )
  })
}
