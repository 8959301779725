import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { headerAnimation } from "../animations/headerAnimation/headerAnimation"
import { ThemeProvider } from "styled-components"
import { GlobalStyle, theme } from "../utils/theme"
import {
  Navigation,
  HelloSection,
  AboutMeSection,
  FreeTimeSection,
  ProjectsSection,
  ToolSection,
  BlogSection,
  ContactSection,
  FooterSection,
} from "../sections/index.sections"
import StoreProvider from "../store/StoreProvider"
import { StyledContactFloater } from "../Components/styled-components/index.styledComponents"
import { ContactIcons } from "../Components/ContactIcons"
import PageLoader from "../Components/PageLoader"
import { sectionAnimation } from "../animations/sectionAnimation/sectionAnimation"

const handleAnimation = async () => {
  await headerAnimation()
  sectionAnimation()
}

const IndexPage = ({ data }) => {
  useEffect(() => {
    handleAnimation()
  }, [])
  return (
    <>
      <PageLoader />
      <GlobalStyle />
      {/* <HelmetComponent data={ this.props.data.allDatoCmsSeo }/> */}
      <StoreProvider>
        <ThemeProvider theme={theme}>
          <StyledContactFloater className="contact-floater">
            <ContactIcons column fixedFloater />
          </StyledContactFloater>
          <StyledContactFloater right className="contact-floater">
            <a href={`mailto:${data.allDatoCmsContactmail.nodes[0].email}`}>
              {data.allDatoCmsContactmail.nodes[0].email}
            </a>
          </StyledContactFloater>
          <div className="page-wrapper">
            <Navigation />
            <main>
              <HelloSection />
              <AboutMeSection />
              <ProjectsSection />
              <ToolSection />
              <FreeTimeSection />
              <BlogSection data={data} />
              <ContactSection />
            </main>
            <FooterSection />
          </div>
        </ThemeProvider>
      </StoreProvider>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query queryIndex {
    allDatoCmsArticle(
      limit: 10
      sort: { order: DESC, fields: meta___createdAt }
    ) {
      edges {
        node {
          body {
            value
          }
          id
          slug
          description
          language
          title
          thumbnail {
            url
          }
        }
      }
    }
    allDatoCmsContactmail {
      nodes {
        email
      }
    }
    allDatoCmsSeo {
      edges {
        node {
          title
          description
          image
          canonical
          ogTitle
          ogDescription
          ogUrl
          ogImage
        }
      }
    }
  }
`
