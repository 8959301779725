import gsap from "gsap"

export const desktopMenuAnimation = async () => {
  const menu = document.querySelector(".menu")
  const asideImages = [
    ...document.querySelectorAll(".navigation-aside-submenu__image-container"),
  ]

  const buttonsTimeline = gsap.timeline({})
  const navigation = gsap.timeline()

  buttonsTimeline.fromTo(
    menu,
    { x: "50", autoAlpha: 0 },
    { x: "0", autoAlpha: 1, duration: 1 }
  )

  Promise.all(
    asideImages.map(async image => {
      await navigation.fromTo(
        image,
        { scale: 0.8, autoAlpha: 0 },
        { scale: 1, autoAlpha: 1, duration: 0.1 }
      )
    })
  )
}
