import React from "react"
import src from "../assets/svg/logo/Bsroka-logos_black.png"

const PageLoader = () => {
  return (
    <section id="preloader">
      <figure className="preloader__image-container">
        <img className="preloader__logo" src={src} alt="" />
      </figure>
    </section>
  )
}

export default PageLoader
